import { idApiTargets } from '../app/config/apis/id/id-api-targets';
import { AvailableApisType } from './assets/available-apis.type';
import { EnvironmentInterface } from './assets/environment.interface';
import { EnvironmentType } from './assets/environment-type';
import { notificationApiTargets } from '../app/config/apis/notification/notification-api-targets';

export const environment = {
  apis: {
    id: idApiTargets.staging,
    notification: notificationApiTargets.staging,
  } as Record<AvailableApisType, EnvironmentInterface>,
  authBundle: {
    debug: false,
  },
  oidc: {
    debug: false,
  },
  loadingScreen: {
    debug: false,
  },
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
  },
  frontend: {
    baseUrl: 'https://angular.komponentbibliotek.fagforbundet.dev',
    redirectUrl: 'https://angular.komponentbibliotek.fagforbundet.dev/login',
    postLogoutRedirectUri:
      'https://angular.komponentbibliotek.fagforbundet.dev',
  },
  requestBundle: {
    debug: false,
  },
  router: {
    debug: false,
  },
  pageTitlePrefix: 'Angular Komponentbibliotek',
  type: EnvironmentType.STAGING,
};
