import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  FfNgxCheckAuthHelper,
  FfNgxLoadingScreenComponent,
  FfNgxStorageService,
} from '@fagforbundet/ngx-components';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { RequestServiceInMemoryStoreNames } from '@core/enums/request-service-in-memory-store-names';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FfNgxLoadingScreenComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _checkAuthHelper: FfNgxCheckAuthHelper,
    private _publicEventsService: PublicEventsService,
    private _storageService: FfNgxStorageService,
  ) {}

  ngOnInit(): void {
    this._checkAuthHelper.checkAuthAndRenew().subscribe();

    this._publicEventsService
      .registerForEvents()
      .pipe(filter((event) => event.type === EventTypes.SilentRenewFailed))
      .subscribe(() => {
        // Reloading to have the current URL stored for redirect after authn
        location.reload();
      });

    Object.entries(RequestServiceInMemoryStoreNames as object).forEach(
      (e: [string, string]) => {
        this._storageService.useInMemoryStorage(e[1]);
      },
    );
  }
}
