import { FfNgxStorage } from './ff-ngx-storage';
import { FfNgxStorageData } from '../types/ff-ngx-storage-data';
import { FfNgxStorageDataChangeEventType } from '../enums/ff-ngx-storage-data-change-event-type';

export class FfNgxInMemoryStorage extends FfNgxStorage {
  private _data$ = new Map<string, any>();

  get length(): number {
    return this._data$.size;
  }

  clear(): void {
    this._data$.clear();
  }

  getItem(key: string): FfNgxStorageData | null {
    return this._data$.get(key) || null;
  }

  key(index: number): string | null {
    if (index > this._data$.size - 1 || index < 0) {
      return null;
    }

    let k = null;
    let i = 0;

    this._data$.forEach(() => {
      if (i === index) {
        k = i;
      }
      i++;
    });

    return k;
  }

  removeItem(key: string): void {
    if (!this._data$.has(key)) {
      return;
    }

    const value = this._data$.get(key);

    this._data$.delete(key);

    this._dataChanges$.next({
      type: FfNgxStorageDataChangeEventType.REMOVE,
      key: key,
      data: value,
      createdAt: new Date(),
      store: this,
    });
  }

  setItem(key: string, value: any, ttl?: number): void {
    const exists = this._data$.has(key);
    const oldData = this._data$.get(key);

    this._data$.set(
      key,
      this.buildValue(value, ttl, this.getTimeoutHandler(key)),
    );

    this._dataChanges$.next({
      type: exists
        ? FfNgxStorageDataChangeEventType.CHANGE
        : FfNgxStorageDataChangeEventType.ADD,
      key: key,
      data: this._data$.get(key),
      oldData,
      createdAt: new Date(),
      store: this,
    });
  }

  getKeys(storeName?: string, withStoreName?: boolean): Map<string, string[]> {
    const keys = new Map<string, string[]>();
    if (withStoreName) {
      keys.set(
        this.name,
        Array.from(this._data$.keys()).map((k) => {
          return this.name.concat('.', k);
        }),
      );
    } else {
      keys.set(this.name, Array.from(this._data$.keys()));
    }

    return keys;
  }
}
