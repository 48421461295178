import { AvailableApisType } from '@environments/assets/available-apis.type';
import { EnvironmentType } from '@environments/assets/environment-type';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';

export class EnvironmentHelper {
  /**
   * Generates API URL for a given path
   */
  static fetchAPIBase(api: AvailableApisType): string {
    try {
      return environment.apis[api].baseUrl;
    } catch (error) {
      // @ts-ignore
      return throwError(() => {
        return error;
      });
    }
  }

  /**
   * Generates URL for a given path to the front end (based on config)
   */
  static getFrontEndURL(): string {
    return environment.frontend.baseUrl;
  }

  static getDateFormat(): string {
    return environment.formats.date;
  }

  static getTimeAndDateFormat(): string {
    return environment.formats.dateTime;
  }

  static isDevelopment(): boolean {
    return environment.type === EnvironmentType.DEVELOPMENT;
  }

  static isStaging(): boolean {
    return environment.type === EnvironmentType.STAGING;
  }

  static isProduction(): boolean {
    return environment.type === EnvironmentType.PRODUCTION;
  }

  static isIdPortalRequest(url: string): boolean {
    return new URL(url).origin === EnvironmentHelper.fetchAPIBase('id');
  }
}
