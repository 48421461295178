import { FfNgxRequestBundleConfig } from '@fagforbundet/ngx-components';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FF_NGX_REQUEST_BUNDLE_API_CONFIGS } from '@fagforbundet/ngx-components';
import { notificationApiConfig } from '@config/apis/notification/notification-api.config';
import { idApiConfig } from '@config/apis/id/id-api.config';
import { brregApiConfig } from '@config/apis/brreg/brreg-api.config';
import { dummyApiConfig } from '@config/apis/dummy-json/dummy-api.config';
import { environment } from '@environments/environment';

export const requestBundleConfig: FfNgxRequestBundleConfig = {
  debug: environment.requestBundle.debug,
};

export function apiConfigurationProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_REQUEST_BUNDLE_API_CONFIGS,
      useValue: [
        brregApiConfig,
        dummyApiConfig,
        idApiConfig,
        notificationApiConfig,
      ],
    },
  ]);
}
