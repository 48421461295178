export class FfNgxUrlHelper {
  static getPathname(url: URL | string): string {
    if (typeof url === 'string') {
      url = new URL(url);
    }

    return url.pathname;
  }

  static createUrl(
    baseUrl: string,
    path: string,
    variables?: { [key: string]: any },
    queryParams?: { [key: string]: any },
  ): URL {
    const urlArray = path.split('/').map((segment: any) => {
      return variables != null && segment.charAt(0) === ':'
        ? variables[segment.substring(1)]
        : segment;
    });

    let url = baseUrl + urlArray.join('/');

    // Add query params
    if (queryParams && Object.entries(queryParams).length > 0) {
      url += '?';

      const params: string[] = [];

      for (const [key, value] of Object.entries(queryParams)) {
        params.push(key + '=' + value);
      }

      url += params.join('&');
    }

    return new URL(url);
  }

  static isMatch(urlPattern: string, urlToMatch: string): boolean {
    let patternUrl: URL;
    let actualUrl: URL;
    try {
      patternUrl = new URL(urlPattern);
      actualUrl = new URL(urlToMatch);
    } catch (e) {
      return false;
    }

    const patternUrlPathSegments = patternUrl.pathname
      .split('/')
      .filter((s) => {
        return s !== '';
      });
    const urlToMatchPathSegments = actualUrl.pathname.split('/').filter((s) => {
      return s !== '';
    });

    let isMatch = true;

    if (patternUrlPathSegments.length !== urlToMatchPathSegments.length) {
      isMatch = false;
    }

    if (!isMatch) {
      return isMatch;
    }

    patternUrlPathSegments.forEach((segment, index) => {
      if (segment.charAt(0) === ':') {
        return;
      }

      if (segment !== urlToMatchPathSegments[index]) {
        isMatch = false;
      }
    });

    return isMatch;
  }
}
