import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  FfNgxLoadingScreenService,
  LoadingScreenDependencies,
} from './ff-ngx-loading-screen.service';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { tap } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FfNgxLoadingIndicatorComponent } from '../ngx-loading-indicator/ff-ngx-loading-indicator.component';

@Component({
  selector: 'ff-ngx-loading-screen',
  standalone: true,
  templateUrl: './ff-ngx-loading-screen.component.html',
  styleUrls: ['./ff-ngx-loading-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FfNgxLoadingIndicatorComponent],
})
export class FfNgxLoadingScreenComponent implements OnInit {
  hidden = true;
  spinnerStroke = '#333';

  @HostBinding('attr.role')
  role = 'dialog';

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _destroyRef: DestroyRef,
    private readonly _loadingScreenService: FfNgxLoadingScreenService,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.#listenToStateChange();
    this.#handleRoutingEvents();
  }

  #handleRoutingEvents(): void {
    this._router.events
      .pipe(
        tap((event) => {
          switch (event.constructor.name) {
            case NavigationCancel.name:
              this._loadingScreenService.removeDependant(
                LoadingScreenDependencies.NAVIGATION,
              );
              break;
            case NavigationEnd.name:
              this._loadingScreenService.removeDependant(
                LoadingScreenDependencies.NAVIGATION,
              );
              break;
            case NavigationError.name:
              this._loadingScreenService.removeDependant(
                LoadingScreenDependencies.NAVIGATION,
              );
              break;
            case NavigationStart.name:
              this._loadingScreenService.addDependant(
                LoadingScreenDependencies.NAVIGATION,
              );
              break;
          }
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  #listenToStateChange(): void {
    this._loadingScreenService
      .getSubject()
      .pipe(
        tap((state: boolean) => {
          this.hidden = state;

          if (this.hidden) {
            document.body.classList.remove('no-scroll');
          } else {
            document.body.classList.add('no-scroll');
          }

          this._cdr.detectChanges();
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }
}
