import {
  EnvironmentProviders,
  importProvidersFrom,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  AuthModule,
  OpenIdConfiguration,
  provideAuth,
} from 'angular-auth-oidc-client';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FfNgxAuthInterceptor } from './interceptors/ff-ngx-auth.interceptor';
import { FF_NGX_AUTH_BUNDLE_DEFAULT_CONFIG } from './ff-ngx-auth-bundle-default-config';
import { FfNgxAuthBundleConfig } from './interfaces/ff-ngx-auth-bundle.config';
import { FfNgxAuthBundleInternalConfigType } from './types/ff-ngx-auth-bundle-internal-config.type';
import { FfNgxConfigHelper } from '../ngx-helpers-bundle/ff-ngx-config.helper';

export const FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG =
  new InjectionToken<FfNgxAuthBundleInternalConfigType>(
    'FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG',
  );

export function ffNgxAuthBundleProvider(
  authConfig: OpenIdConfiguration,
  bundleConfig?: FfNgxAuthBundleConfig,
): EnvironmentProviders {
  const combinedConfig =
    FfNgxConfigHelper.combineConfigValues<FfNgxAuthBundleInternalConfigType>(
      FF_NGX_AUTH_BUNDLE_DEFAULT_CONFIG,
      bundleConfig,
    );

  if (combinedConfig.authorize.useInterceptor) {
    if (combinedConfig?.debug) {
      console.log('Providing config with interceptor:', combinedConfig);
    }
    return provideWithInterceptor(authConfig, combinedConfig);
  }

  if (combinedConfig?.debug) {
    console.log('Providing config without interceptor:', combinedConfig);
  }

  return provideWithoutInterceptor(authConfig, combinedConfig);
}

function provideWithoutInterceptor(
  authConfig: OpenIdConfiguration,
  combinedConfig: FfNgxAuthBundleInternalConfigType,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG,
      useValue: combinedConfig,
    },
    importProvidersFrom(AuthModule),
    provideAuth({
      config: authConfig,
    }),
  ]);
}

function provideWithInterceptor(
  authConfig: OpenIdConfiguration,
  combinedConfig: FfNgxAuthBundleInternalConfigType,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG,
      useValue: combinedConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FfNgxAuthInterceptor,
      multi: true,
    },
    importProvidersFrom(AuthModule),
    provideAuth({
      config: authConfig,
    }),
  ]);
}
