import { DestroyRef, Inject, Injectable } from '@angular/core';
import { FfNgxInMemoryStorage } from './stores/ff-ngx-in-memory-storage';
import { FfNgxLocalStorage } from './stores/ff-ngx-local-storage';
import { FfNgxSessionStorage } from './stores/ff-ngx-session-storage';
import { FfNgxStorage } from './stores/ff-ngx-storage';
import { FF_NGX_STORAGE_BUNDLE_PROVIDER_CONFIG } from './ff-ngx-storage-bundle.provider';
import { FfNgxStorageBundleInternalConfigType } from './types/ff-ngx-storage-bundle-internal-config.type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FfNgxStorageDataChangeEventType } from './enums/ff-ngx-storage-data-change-event-type';

@Injectable({
  providedIn: 'root',
})
export class FfNgxStorageService {
  STORE_NAME_REGEX = new RegExp(
    FfNgxStorage.STORE_NAME_PREFIX +
      FfNgxStorage.ALLOWED_STORE_NAME_REGEX.source +
      '.(.+)',
  );

  private stores = new Map<string, FfNgxStorage>();

  constructor(
    @Inject(FF_NGX_STORAGE_BUNDLE_PROVIDER_CONFIG)
    private _config: FfNgxStorageBundleInternalConfigType,
    private _destroyRef: DestroyRef,
  ) {
    this.loadLocalAndSessionStorages();

    if (this._config.debug) {
      this.stores.forEach((store) => {
        this.subscribeToStoreForDebugging(store);
      });
    }
  }

  /**
   * Returns instance of the given store
   */
  getStore(store: string = this._config.globalStoreName): FfNgxStorage {
    if (this._config.debug) {
      console.log(`Attempting to get store '${store}'...`);
    }

    this.validateStoreName(store);
    this.throwIfStoreDoesntExist(store);

    return this.stores.get(store)!;
  }

  /**
   * Sets localStorage as the active storage for the given store.
   * Store is created if it doesn't exist.
   */
  useLocalStorage(store: string = this._config.globalStoreName): FfNgxStorage {
    if (this._config.debug) {
      console.log(`Using local storage for '${store}'`);
    }
    this.validateStoreName(store);
    if (this.stores.get(store) instanceof FfNgxLocalStorage) {
      return this.stores.get(store)!;
    }

    const ffNgxLocalStorage = new FfNgxLocalStorage(store);

    if (this.stores.has(store)) {
      if (this._config.debug) {
        console.log(
          `Store '${store}' already exists. Moving data from it to the local storage store.`,
        );
      }
      this.moveStoreTo(this.stores.get(store)!, ffNgxLocalStorage);
    } else if (this._config.debug) {
      this.subscribeToStoreForDebugging(ffNgxLocalStorage);
    }

    this.stores.set(store, ffNgxLocalStorage);

    return this.stores.get(store)!;
  }

  /**
   * Sets sessionStorage as the active storage for the given store.
   * Store is created if it doesn't exist.
   */
  useSessionStorage(
    store: string = this._config.globalStoreName,
  ): FfNgxStorage {
    if (this._config.debug) {
      console.log(`Using session storage for '${store}'`);
    }

    this.validateStoreName(store);
    if (this.stores.get(store) instanceof FfNgxSessionStorage) {
      return this.stores.get(store)!;
    }

    const ffNgxSessionStorage = new FfNgxSessionStorage(store);

    if (this.stores.has(store)) {
      if (this._config.debug) {
        console.log(
          `Store '${store}' already exists. Moving data from it to the session storage store.`,
        );
      }
      this.moveStoreTo(this.stores.get(store)!, ffNgxSessionStorage);
    } else if (this._config.debug) {
      this.subscribeToStoreForDebugging(ffNgxSessionStorage);
    }

    this.stores.set(store, ffNgxSessionStorage);

    return this.stores.get(store)!;
  }

  /**
   * Sets FfNgxInMemoryStore as the active storage for the given store.
   * Store is created if it doesn't exist.
   */
  useInMemoryStorage(
    store: string = this._config.globalStoreName,
  ): FfNgxStorage {
    if (this._config.debug) {
      console.log(`Using in-memory storage for '${store}'`);
    }

    this.validateStoreName(store);
    if (this.stores.get(store) instanceof FfNgxInMemoryStorage) {
      return this.stores.get(store)!;
    }

    const inMemoryStore = new FfNgxInMemoryStorage(store);

    if (this.stores.has(store)) {
      if (this._config.debug) {
        console.log(
          `Store '${store}' already exists. Moving data from it to the in-memory store.`,
        );
      }

      this.moveStoreTo(
        this.stores.get(store)!,
        inMemoryStore as unknown as FfNgxStorage,
      );
    } else if (this._config.debug) {
      this.subscribeToStoreForDebugging(inMemoryStore);
    }

    this.stores.set(store, inMemoryStore as unknown as FfNgxStorage);

    return this.stores.get(store)!;
  }

  /**
   * Discovers and creates instances of all
   * localStorage and sessionStorage stores based on keys
   */
  loadLocalAndSessionStorages(): void {
    if (this._config.debug) {
      console.log(`Loading local and session storages...`);
    }

    const ffNgxLocalStorage = new FfNgxLocalStorage('tmpLocal');
    const ffNgxSessionStorage = new FfNgxSessionStorage('tmpSession');

    const localStorageStores = new Set<string>();
    const sessionStorageStores = new Set<string>();

    ffNgxLocalStorage.getKeys(undefined, true).forEach((k) => {
      k.forEach((key) => {
        localStorageStores.add(key.split(/\.(.*)/)[0]);
      });
    });
    ffNgxSessionStorage.getKeys(undefined, true).forEach((k) => {
      k.forEach((key) => {
        sessionStorageStores.add(key.split(/\.(.*)/)[0]);
      });
    });

    localStorageStores.forEach((k) => {
      const lStore = new FfNgxLocalStorage(k);
      lStore.resetValues();
      this.stores.set(k, lStore);
    });

    sessionStorageStores.forEach((k) => {
      const sStore = new FfNgxSessionStorage(k);
      sStore.resetValues();
      this.stores.set(k, sStore);
    });

    if (this._config.debug) {
      console.log(`Local and session storages loaded`);
    }
  }

  /**
   * Clears and removes the given store
   *
   * @param store
   */
  removeStore(store: string): void {
    if (this._config.debug) {
      console.log(`Removing store '${store}'`);
    }

    this.validateStoreName(store);
    this.stores.get(store)?.shutDown();
    this.stores.delete(store);
  }

  removeAllStores(): void {
    if (this._config.debug) {
      console.log(`Removing all stores...`);
    }

    this.stores.forEach((store) => {
      this.removeStore(store.getName());
    });
  }

  validateStoreName(store: string): void {
    if (this._config.debug) {
      console.log(`Validating store name '${store}'`);
    }

    const r = new RegExp(
      '^' + FfNgxStorage.ALLOWED_STORE_NAME_REGEX.source + '$',
    );

    if (!r.test(store)) {
      throw new Error(
        `Invalid store name (${store}). Allowed characters: a-z, A-Z, 0-9, #, -, _, $, |`,
      );
    }

    if (this._config.debug) {
      console.log(`Store name '${store}' is valid.`);
    }
  }

  private subscribeToStoreForDebugging(store: FfNgxStorage): void {
    store
      .dataChanges()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((e) => {
        switch (e.type) {
          case FfNgxStorageDataChangeEventType.ADD:
            console.log(
              `Added data to the key '${
                e.key
              }' in the store '${e.store.getName()}':`,
              e,
            );
            break;
          case FfNgxStorageDataChangeEventType.CHANGE:
            console.log(
              `Changed the data on the key '${
                e.key
              }' in the store '${e.store.getName()}':`,
              e,
            );
            break;
          case FfNgxStorageDataChangeEventType.REMOVE:
            console.log(
              `Removed the key '${
                e.key
              }' from the store '${e.store.getName()}':`,
              e,
            );
            break;
        }
      });
  }

  /**
   * @param store
   * @param newStore
   * @private
   */
  private moveStoreTo(store: FfNgxStorage, newStore: FfNgxStorage): void {
    if (this._config.debug) {
      console.log(
        `Moving store '${store.getName()}' to new store '${newStore.getName()}'.`,
        store,
        newStore,
      );
    }

    newStore.setDataChanges$(store.getDataChanges$());

    const keys = store.getKeys(undefined, true).get(store.getName()) || [];

    keys.forEach((k) => {
      const bareKeyName = k.split(/\.(.*)/)[1];
      newStore.setItem(bareKeyName, store.getItem(bareKeyName));
    });

    if (this._config.debug) {
      console.log(`Shutting down old store...`);
    }

    store.shutDown(false);
  }

  private throwIfStoreDoesntExist(store: string): void {
    if (this._config.debug) {
      console.log(`Ensuring that store '${store}' exists`);
    }

    if (!this.stores.has(store)) {
      throw new Error(`No store with the given name (${store}) was found`);
    }

    if (this._config.debug) {
      console.log(`Store '${store}' exists.`);
    }
  }
}
