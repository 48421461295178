import {
  EnvironmentProviders,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import { FfNgxRequestBundleInternalConfig } from './types/ff-ngx-request-bundle-internal-config';
import { FfNgxRequestBundleConfig } from './ff-ngx-request-bundle.config';
import { FF_NGX_REQUEST_BUNDLE_DEFAULT_CONFIG } from './default-values/ff-ngx-request-bundle-default-config';
import { FfNgxApiConfiguration } from './types/ff-ngx-api-configuration';
import { FfNgxConfigHelper } from '../ngx-helpers-bundle/ff-ngx-config.helper';

export const FF_NGX_REQUEST_BUNDLE_PROVIDER_CONFIG =
  new InjectionToken<FfNgxRequestBundleInternalConfig>(
    'FF_NGX_REQUEST_BUNDLE_PROVIDER_CONFIG',
  );

export const FF_NGX_REQUEST_BUNDLE_API_CONFIGS = new InjectionToken<
  FfNgxApiConfiguration<any>[]
>('FF_NGX_REQUEST_BUNDLE_API_CONFIGS');

export function ffNgxRequestsBundleProvider(
  bundleConfig?: FfNgxRequestBundleConfig,
): EnvironmentProviders {
  const combinedConfig =
    FfNgxConfigHelper.combineConfigValues<FfNgxRequestBundleInternalConfig>(
      FF_NGX_REQUEST_BUNDLE_DEFAULT_CONFIG,
      bundleConfig,
    );

  if (combinedConfig?.debug) {
    console.log('Providing config:', combinedConfig);
  }

  return makeEnvironmentProviders([
    {
      provide: FF_NGX_REQUEST_BUNDLE_PROVIDER_CONFIG,
      useValue: combinedConfig,
    },
  ]);
}
