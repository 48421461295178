import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const dummyApiEndpoints = {
  GET_PRODUCT: {
    path: '/products/:productId',
  },
  GET_TODOS: {
    path: '/todos',
  },
  GET_USER: {
    path: '/users/:userId',
  },
  NO_CONTENT: {
    path: '/http/204',
  },
  POST_NO_BODY: {
    path: '/http/201',
  },
  POST_PRODUCT: {
    path: '/products/add',
  },
} satisfies FfNgxEndpointRecords;
