import { Inject, Injectable } from '@angular/core';
import {
  AuthenticatedResult,
  LoginResponse,
  OidcSecurityService,
} from 'angular-auth-oidc-client';
import { catchError, filter, map, take } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';
import { finalize, Observable, of, tap } from 'rxjs';
import { FfNgxAuthService } from '../ff-ngx-auth.service';
import { FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG } from '../ff-ngx-auth-bundle.provider';
import { FfNgxAuthBundleInternalConfigType } from '../types/ff-ngx-auth-bundle-internal-config.type';
import {
  FfNgxLoadingScreenService,
  LoadingScreenDependencies,
} from '../../ngx-loading-screen/ff-ngx-loading-screen.service';
import { FfNgxStorageService } from '../../ngx-storage-bundle/ff-ngx-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FfNgxCheckAuthHelper {
  constructor(
    private _authService: FfNgxAuthService,
    @Inject(FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG)
    private _config: FfNgxAuthBundleInternalConfigType,
    private _loadingScreenService: FfNgxLoadingScreenService,
    private _oidcSecurityService: OidcSecurityService,
    private _storageService: FfNgxStorageService,
    private _router: Router,
  ) {}

  checkAuthGuardHelper(): Observable<boolean | UrlTree> {
    if (this._config.debug) {
      console.log('checkAuthGuardHelper ran');
    }

    return this._oidcSecurityService.isAuthenticated$.pipe(
      filter((value) => this.isAuthenticatedResult(value)),
      take(1),
      map((authResult) => {
        if (this._config.debug) {
          console.log('Received authenticated result: ', { ...authResult });
        }

        if (!authResult!.isAuthenticated) {
          const url = new URL(location.toString());
          if ('/' !== url.pathname) {
            this._authService.setStoredRedirectRoute(url);
          }
          return this._router.createUrlTree(['/']);
        }

        return authResult!.isAuthenticated;
      }),
    );
  }

  checkAuthAndRenew() {
    this._loadingScreenService.addDependant(
      LoadingScreenDependencies.AUTHENTICATION,
    );

    return this._oidcSecurityService.checkAuthIncludingServer().pipe(
      tap((loginResponse: LoginResponse) => {
        if (this._config.debug) {
          console.log('Received login response: ', { ...loginResponse });
        }

        if (loginResponse.isAuthenticated) {
          if (this._authService.isRememberMe()) {
            if (this._config.debug) {
              console.log('Remember me was set');
            }

            this._storageService.useLocalStorage(this._config.storageName);
            this._authService.setStoredUser({
              sub: loginResponse.userData.sub,
              firstName: loginResponse.userData.given_name,
              lastName: loginResponse.userData.family_name,
            });
          } else {
            if (this._config.debug) {
              console.log('Remember me was unset');
            }

            this._storageService.useSessionStorage(this._config.storageName);
          }
        }
      }),
      catchError((e) => {
        if (this._config.debug) {
          console.error(e);
        }
        const url = new URL(location.toString());
        if ('/' !== url.pathname) {
          this._authService.setStoredRedirectRoute(url);
        }
        void this._router.navigate(['/']);
        return of(null);
      }),
      finalize(() => {
        this._authService.authCompleted();
        this._loadingScreenService.removeDependant(
          LoadingScreenDependencies.AUTHENTICATION,
        );
      }),
    );
  }

  private isAuthenticatedResult(value: any): value is AuthenticatedResult {
    if (this._config.debug) {
      console.log('Checking for AuthenticatedResult', value);
    }
    const keys = Object.keys(value);

    const t =
      keys.length === 2 &&
      keys.includes('isAuthenticated') &&
      keys.includes('allConfigsAuthenticated');

    if (this._config.debug) {
      console.log(
        t
          ? 'Value looks like AuthenticatedResult'
          : 'Value does not look like AuthenticatedResult',
      );
    }

    return t;
  }
}
