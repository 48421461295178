import {
  EnvironmentProviders,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import { FF_NGX_DEFAULT_ERROR_MESSAGES } from './ff-ngx-error-messages-default.config';
import { FfNgxControlErrorMessages } from './ff-ngx-error-messages.type';

export const FF_NGX_FORM_ERRORS = new InjectionToken<FfNgxControlErrorMessages>(
  'FF_NGX_FORM_ERRORS',
);

export function ffNgxFormErrorProvider(
  customErrors?: FfNgxControlErrorMessages,
): EnvironmentProviders {
  const combinedErrors = Object.assign(
    FF_NGX_DEFAULT_ERROR_MESSAGES,
    customErrors,
  );

  return makeEnvironmentProviders([
    {
      provide: FF_NGX_FORM_ERRORS,
      useValue: combinedErrors,
    },
  ]);
}
