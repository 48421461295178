import { Inject, Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG } from '../ff-ngx-auth-bundle.provider';
import { FfNgxAuthBundleInternalConfigType } from '../types/ff-ngx-auth-bundle-internal-config.type';
import { FfNgxStorageService } from '../../ngx-storage-bundle/ff-ngx-storage.service';

@Injectable()
export class FfNgxAuthOidcCustomStore extends AbstractSecurityStorage {
  constructor(
    @Inject(FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG)
    private _config: FfNgxAuthBundleInternalConfigType,
    private _storageService: FfNgxStorageService,
  ) {
    super();
  }

  public read(key: string): any {
    let store;
    try {
      store = this._storageService.getStore(this._config.storageName);
    } catch (e) {
      return undefined;
    }
    const item = store.getItem(key, false);
    return item ? item.data : undefined;
  }

  public clear(): void {
    let store;
    try {
      store = this._storageService.getStore(this._config.storageName);
    } catch (e) {
      return undefined;
    }
    store.clear();
  }

  public write(key: string, value: any): void {
    let store;
    try {
      store = this._storageService.getStore(this._config.storageName);
    } catch (e) {
      return undefined;
    }
    store.setItem(key, value);
  }

  public remove(key: string): void {
    let store;
    try {
      store = this._storageService.getStore(this._config.storageName);
    } catch (e) {
      return undefined;
    }
    store.removeItem(key);
  }
}
