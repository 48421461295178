import { FfNgxAuthBundleConfig } from './interfaces/ff-ngx-auth-bundle.config';

export const FF_NGX_AUTH_BUNDLE_DEFAULT_CONFIG: FfNgxAuthBundleConfig = {
  debug: false,
  authorize: {
    useInterceptor: true,
  },
  storageName: 'FF_NGX_AUTH_BUNDLE_STORAGE',
  storageKeys: {
    redirectRoute: 'storedRedirectRoute',
    redirectRouteTimestamp: 'storedRedirectRouteTimestamp',
    uuid: 'storedUuid',
    user: 'storedUser',
    firstName: 'storedFirstName',
    lastName: 'storedLastName',
    rememberMe: 'storedRememberMe',
  },
  ttl: {
    redirectRoute: 900000,
  },
};
