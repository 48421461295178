import { FfNgxStorageData } from '../types/ff-ngx-storage-data';
import { Observable, Subject } from 'rxjs';
import { FfNgxStorageDataChangeEvent } from '../types/ff-ngx-storage-data-change-event';

export abstract class FfNgxStorage {
  static ALLOWED_STORE_NAME_REGEX = /([a-zA-Z0-9#\-$|_]+)/;
  static STORE_NAME_PREFIX = 'ff-ngx-store.';

  protected _dataChanges$ = new Subject<FfNgxStorageDataChangeEvent>();

  constructor(protected name: string) {}

  abstract get length(): number;

  getDataChanges$(): Subject<FfNgxStorageDataChangeEvent> {
    return this._dataChanges$;
  }

  setDataChanges$(value: Subject<FfNgxStorageDataChangeEvent>): void {
    this._dataChanges$ = value;
  }

  getName(): string {
    return this.name;
  }

  getType(): string {
    return this.constructor.name;
  }

  abstract getKeys(
    storeName?: string,
    withStoreName?: boolean,
  ): Map<string, string[]>;

  isStorageData(obj: unknown): obj is FfNgxStorageData {
    return (
      !!obj &&
      typeof obj === 'object' &&
      Object.hasOwn(obj, 'createdAt') &&
      typeof (obj as FfNgxStorageData).createdAt === 'object' &&
      Object.hasOwn(obj, 'updatedAt') &&
      typeof (obj as FfNgxStorageData).updatedAt === 'object'
    );
  }

  abstract clear(): void;

  shutDown(completeDataChanges$: boolean = true): void {
    this.clear();
    if (completeDataChanges$) {
      this._dataChanges$.complete();
    }
  }

  abstract getItem(
    key: string,
    parseDatesInData?: boolean,
  ): FfNgxStorageData | null;

  abstract key(index: number): string | null;

  abstract removeItem(key: string): void;

  abstract setItem(key: string, value: any, ttl?: number): void;

  dataChanges(): Observable<FfNgxStorageDataChangeEvent> {
    return this._dataChanges$.asObservable();
  }

  isExpired(data: FfNgxStorageData): boolean {
    return (
      undefined !== data.expiresAt &&
      data.expiresAt.getTime() <= new Date().getTime()
    );
  }

  getPrefix(name?: string): string {
    return name
      ? FfNgxStorage.STORE_NAME_PREFIX + name + '.'
      : FfNgxStorage.STORE_NAME_PREFIX + this.name + '.';
  }

  protected buildKey(key: string): string {
    return this.getPrefix() + key;
  }

  protected buildValue(
    value: any,
    ttl?: number,
    timeoutHandler?: TimerHandler,
  ): FfNgxStorageData {
    if (this.isStorageData(value)) {
      return this.buildValueFromStorageData(value, ttl, timeoutHandler);
    }

    return this.buildValueFromAny(value, ttl, timeoutHandler);
  }

  protected getExpirationDateFromTtl(ttl: number): Date {
    return new Date(new Date().getTime() + ttl);
  }

  protected getTtl(data: FfNgxStorageData): number {
    return !this.isExpired(data) && data.expiresAt
      ? data.expiresAt.getTime() - new Date().getTime()
      : 0;
  }

  protected getTimeoutHandler(key: string): TimerHandler {
    return () => {
      this.removeItem(key);
    };
  }

  private buildValueFromStorageData(
    value: FfNgxStorageData,
    ttl?: number,
    timeoutHandler?: TimerHandler,
  ): FfNgxStorageData {
    if (ttl && timeoutHandler) {
      value.expiresAt = this.getExpirationDateFromTtl(ttl);
      clearTimeout(value.timeoutId);
      value.timeoutId = setTimeout(timeoutHandler, this.getTtl(value));
    } else if (value.expiresAt && timeoutHandler) {
      clearTimeout(value.timeoutId);
      value.timeoutId = setTimeout(timeoutHandler, this.getTtl(value));
    }

    value.updatedAt = new Date();

    return value;
  }

  private buildValueFromAny(
    value: any,
    ttl?: number,
    timeoutHandler?: TimerHandler,
  ): FfNgxStorageData {
    const d: FfNgxStorageData = {
      createdAt: new Date(),
      updatedAt: new Date(),
      data: value,
    };

    if (!(ttl && timeoutHandler)) {
      return d;
    }

    d.expiresAt = this.getExpirationDateFromTtl(ttl);
    d.timeoutId = setTimeout(timeoutHandler, this.getTtl(d));

    return d;
  }
}
