import { Routes } from '@angular/router';
import { ffNgxAuthCanMatchGuard } from '@fagforbundet/ngx-components';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@modules/public/public.routes').then((m) => m.routes),
  },
  {
    path: 'component-previews',
    loadChildren: () =>
      import('@modules/component-previews/component-previews.routes').then(
        (m) => m.routes,
      ),
    canMatch: [ffNgxAuthCanMatchGuard],
  },
  { path: 'unauthorized', redirectTo: '/' },
];
