import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FF_NGX_LOADING_SCREEN_PROVIDER_CONFIG } from './ff-ngx-loading-screen.provider';
import { FfNgxLoadingScreenConfig } from './ff-ngx-loading-screen.config';

@Injectable({
  providedIn: 'root',
})
export class FfNgxLoadingScreenService {
  private _dependants: Array<string> = [];
  private _stateSubject: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(FF_NGX_LOADING_SCREEN_PROVIDER_CONFIG)
    private config: FfNgxLoadingScreenConfig,
  ) {}

  getSubject(): Observable<boolean> {
    return this._stateSubject;
  }

  addDependant(dependant: string): void {
    if (this.config.debug) {
      console.log('Adding dependant: ' + dependant);
    }

    if (this._dependants.indexOf(dependant) === -1) {
      this._dependants.push(dependant);

      if (this._dependants.length > 0) {
        this._show();
      }
    }
  }

  removeDependant(dependant: string): void {
    if (this.config.debug) {
      console.log('Removing dependant: ' + dependant);
    }
    if (this._dependants.indexOf(dependant) !== -1) {
      this._dependants.splice(this._dependants.indexOf(dependant), 1);
    }

    if (this._dependants.length < 1) {
      this._hide();
    }
  }

  private _hide(): void {
    if (this.config.debug) {
      console.log('Hiding loading screen');
    }
    this._stateSubject.next(true);
  }

  private _show(): void {
    if (this.config.debug) {
      console.log('Showing loading screen');
    }
    this._stateSubject.next(false);
  }
}

export enum LoadingScreenDependencies {
  AUTHENTICATION = 'AUTHENTICATION',
  NAVIGATION = 'NAVIGATION',
}
