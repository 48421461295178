import { FileUploadErrorName } from './file-upload-error.type';
import { FfNgxControlErrorMessages } from '../../ngx-control-error/ff-ngx-error-messages.type';
import { FfNgxFileSizePipe } from '../../ngx-helpers-bundle/pipes/ff-ngx-file-size.pipe';

const fileSizePipe = new FfNgxFileSizePipe();
export const FF_NGX_FILE_UPLOAD_ERROR_MESSAGES: FfNgxControlErrorMessages<FileUploadErrorName> =
  {
    uploadFileTypes: ({ allowed, violations }) => {
      let violationString = '';

      if (violations.length === 1) {
        violationString = `en ${violations[0].toUpperCase()} fil`;
      } else {
        violationString = ` og ${violations[
          violations.length - 1
        ].toUpperCase()}`;
        violationString = `filer av typen ${violations
          .slice(0, -1)
          .join(', ')
          .toUpperCase()}  ${violationString}`;
      }
      let allowedString = '';
      if (allowed === 1) {
        allowedString = `${allowed[0].toUpperCase()}`;
      } else {
        allowedString += ` og ${allowed[allowed.length - 1].toUpperCase()}`;
        allowedString =
          allowed.slice(0, -1).join(', ').toUpperCase() + allowedString;
      }
      return `Du har valgt ${violationString}, men bare ${allowedString} filer er tillatt`;
    },
    uploadMaxFileSize: ({ currentSize, maxSize }) =>
      `Du har lagt til ${fileSizePipe.transform(
        currentSize,
      )}, maks størrelse er ${fileSizePipe.transform(maxSize)}`,
    uploadMaxLength: ({ currentLength, maxLength }) =>
      `Du har lagt til ${currentLength} filer, maks antall er ${maxLength}`,
  };
