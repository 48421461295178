import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { concatMap, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG } from '../ff-ngx-auth-bundle.provider';
import { FfNgxAuthBundleInternalConfigType } from '../types/ff-ngx-auth-bundle-internal-config.type';

@Injectable({
  providedIn: 'root',
})
export class FfNgxAuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG)
    private _config: FfNgxAuthBundleInternalConfigType,
    private _oidcSecurityService: OidcSecurityService,
  ) {}

  /**
   * Intercept requests, add Bearer token if applicable
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this._config.debug) {
      console.log('Running interceptor...');
    }

    if (
      this._config.authorize.requestRequiresAuth &&
      !this._config.authorize.requestRequiresAuth(req)
    ) {
      if (this._config.debug) {
        console.log(
          `The request (${req.url}) does not require auth. Proceeding without Authorization header.`,
        );
      }
      return next.handle(req);
    }

    if (this._config.debug) {
      console.log(
        `The request (${req.url}) requires auth. Proceeding with Authorization header.`,
      );
    }

    // Add Bearer token to request and continue
    return this._oidcSecurityService.getAccessToken().pipe(
      first(),
      concatMap((token: string) => {
        req = req.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        });
        return next.handle(req);
      }),
    );
  }
}
