import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const brregApiEndpoints = {
  GET_ENHETER: {
    path: '/enheter',
  },
  GET_ENHET: {
    path: '/enheter/:orgNo',
  },
} satisfies FfNgxEndpointRecords;
