import { Component, HostBinding, Input } from '@angular/core';
import { FfNgxLoadingIndicatorSize } from './types/ff-ngx-loading-indicator-size.type';

@Component({
  standalone: true,
  selector: 'ff-ngx-loading-indicator',
  templateUrl: './ff-ngx-loading-indicator.component.html',
  styleUrls: ['./ff-ngx-loading-indicator.component.scss'],
})
export class FfNgxLoadingIndicatorComponent {
  @Input({required: false})
  done!: boolean;

  @Input({required: false})
  error!: boolean;

  @Input({required: false})
  size?: FfNgxLoadingIndicatorSize;

  @Input({required: false})
  stroke?: string;

  @HostBinding('role')
  readonly role = 'spinner';
}
