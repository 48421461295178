import { FfNgxEmailAddressErrorNameApi } from './types/ff-ngx-email-address-error-name-api';
import { FfNgxEmailAddressErrorName } from './types/ff-ngx-email-address-error-name';
import { FfNgxControlErrorMessages } from '../../ngx-control-error/ff-ngx-error-messages.type';

export const FF_NGX_EMAIL_ADDRESS_ERROR_MESSAGES: FfNgxControlErrorMessages<
  FfNgxEmailAddressErrorNameApi | FfNgxEmailAddressErrorName
> = {
  invalid_dns_error: (error) => 'E-postadressen ser ikke ut til å eksistere',
  invalid_syntax_error: (error) => 'Ikke gyldig e-post-syntaks',
  invalid_email_address_error: (error) => 'Ikke gyldig e-postadresse',
  invalid_7_bit_local_part_error: (error) =>
    'Spesialtegn (æ, ø, å, bokstaver med aksent, osv.) er ikke støttet i e-postadresser',
  emailUnique: (error) => 'E-postadressen er allerede registrert',
  emailCreateFailed: (error) => 'Lagring feilet',
  emailUpdateFailed: (error) => 'Lagring av endringen feilet',
  emailSyntax: (error) => 'Ugyldig format for e-postadresse',
  email: (error) => 'Ugyldig format for e-postadresse',
};
