import { createApiConfiguration } from '@fagforbundet/ngx-components';
import { environment } from '@environments/environment';
import { notificationApiEndpoints } from '@config/apis/notification/notification-api-endpoints';

export const notificationApiConfig = createApiConfiguration<
  typeof notificationApiEndpoints
>({
  baseUrl: environment.apis.notification.baseUrl,
  endpoints: notificationApiEndpoints,
});
