import { Injectable } from '@angular/core';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter } from 'rxjs/operators';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(private readonly _cookiebotService: NgxCookiebotService) {}

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._cookiebotService.onServiceReady$
        .pipe(
          filter((ready: boolean) => {
            return ready;
          }),
          tap(() => {
            this._setUpEventHandlers();
          }),
        )
        .subscribe();

      return resolve();
    });
  }

  private _setUpEventHandlers(): void {
    this._cookiebotService.onConsentReady$
      .pipe(
        tap((e) => {
          this._handleUserConsent();
        }),
      )
      .subscribe();
  }

  private _handleUserConsent(): void {
    if (this._cookiebotService.cookiebot.consent.statistics) {
      console.log(`User consented to statistics`);
    }
  }
}
