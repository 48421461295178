import {
  FfNgxAuthBundleConfig,
  FfNgxEndpointDefinition,
  FfNgxUrlHelper,
  getUrlFromEndpointDefinition,
} from '@fagforbundet/ngx-components';
import { environment } from '@environments/environment';
import { HttpContextToken } from '@angular/common/http';
import { idApiConfig } from '@config/apis/id/id-api.config';

export const REQUEST_REQUIRES_NO_AUTH = new HttpContextToken<boolean>(
  () => true,
);

export const authBundleConfig: FfNgxAuthBundleConfig = {
  debug: environment.authBundle.debug,
  authorize: {
    requestRequiresAuth: (request) => {
      let requiresAuth = true;

      if (
        request.context.has(REQUEST_REQUIRES_NO_AUTH) &&
        request.context.get(REQUEST_REQUIRES_NO_AUTH)
      ) {
        return false;
      }

      let hasMatch = false;
      Object.values(idApiConfig.endpoints).forEach(
        (url: FfNgxEndpointDefinition) => {
          if (
            FfNgxUrlHelper.isMatch(
              getUrlFromEndpointDefinition(url).toString(),
              request.url,
            )
          ) {
            hasMatch = true;
          }
        },
      );

      if (hasMatch) {
        return false;
      }

      if (new RegExp(/^\/?assets\/.*/).test(request.url)) {
        requiresAuth = false;
      }

      return requiresAuth;
    },
  },
};
