import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const notificationApiEndpoints = {
  POST_VALIDATE_EMAIL_ADDRESS: {
    path: '/v2/validate/email-address',
  },
  POST_PARSE_PHONE_NUMBER: {
    path: '/v1/parse/phone-number',
  },
} satisfies FfNgxEndpointRecords;
