import { FfNgxPhoneNumberErrorNameApi } from './types/ff-ngx-phone-number-error-name-api';
import { FfNgxPhoneNumberErrorName } from './types/ff-ngx-phone-number-error-name';
import { FfNgxControlErrorMessages } from '../../ngx-control-error/ff-ngx-error-messages.type';

export const FF_NGX_PHONE_NUMBER_ERROR_MESSAGES: FfNgxControlErrorMessages<
  FfNgxPhoneNumberErrorNameApi | FfNgxPhoneNumberErrorName
> = {
  invalid_country_code: (error) => 'Ugyldig landskode',
  invalid_length: (error) => 'Ugyldig lengde',
  invalid_type: (error) => 'Dette ser ikke ut som et mobilnummer',
  invalid: (error) => 'Uyldig mobilnummer',
  too_long: (error) => 'Nummeret er for langt',
  too_short: (error) => 'Nummeret er for kort',
  phoneUnique: (error) => 'Nummeret eksisterer allerede',
  phoneCreateFailed: (error) => 'Lagring feilet',
  phoneUpdateFailed: (error) => 'Lagring av endringen feilet',
  phoneMinLength: ({ requiredLength, actualLength }) =>
    `Telefonnummer må være minst ${requiredLength} siffer langt`,
  phoneRequired: () => 'Telefonnumer må fylles ut',
};
