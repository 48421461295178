import {
  EnvironmentProviders,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import { FfNgxStorageBundleConfig } from './ff-ngx-storage-bundle.config';
import { FF_NGX_STORAGE_BUNDLE_DEFAULT_CONFIG } from './ff-ngx-storage-bundle-default-config';
import { FfNgxStorageBundleInternalConfigType } from './types/ff-ngx-storage-bundle-internal-config.type';
import { FfNgxConfigHelper } from '../ngx-helpers-bundle/ff-ngx-config.helper';

export const FF_NGX_STORAGE_BUNDLE_PROVIDER_CONFIG =
  new InjectionToken<FfNgxStorageBundleInternalConfigType>(
    'FF_NGX_STORAGE_BUNDLE_PROVIDER_CONFIG',
  );

export function ffNgxStorageBundleProvider(
  bundleConfig?: FfNgxStorageBundleConfig,
): EnvironmentProviders {
  const combinedConfig =
    FfNgxConfigHelper.combineConfigValues<FfNgxStorageBundleInternalConfigType>(
      FF_NGX_STORAGE_BUNDLE_DEFAULT_CONFIG,
      bundleConfig,
    );

  if (combinedConfig?.debug) {
    console.log('Providing config:', combinedConfig);
  }

  return makeEnvironmentProviders([
    {
      provide: FF_NGX_STORAGE_BUNDLE_PROVIDER_CONFIG,
      useValue: combinedConfig,
    },
  ]);
}
