import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { inject } from '@angular/core';
import { FfNgxCheckAuthHelper } from '../helpers/ff-ngx-check-auth.helper';

export const ffNgxAuthCanMatchGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
) => {
  return inject(FfNgxCheckAuthHelper).checkAuthGuardHelper();
};
