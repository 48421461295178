import { FfNgxLoadingScreenConfig } from './ff-ngx-loading-screen.config';
import {
  EnvironmentProviders,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

export const FF_NGX_LOADING_SCREEN_PROVIDER_CONFIG =
  new InjectionToken<FfNgxLoadingScreenConfig>(
    'FF_NGX_LOADING_SCREEN_PROVIDER_CONFIG',
  );

export function ffNgxLoadingScreenProvider(
  config: FfNgxLoadingScreenConfig,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_LOADING_SCREEN_PROVIDER_CONFIG,
      useValue: config,
    },
  ]);
}
