export class FfNgxConfigHelper {
  /**
   * NB!: If overriding reference types, the instances will be regarded as 'object',
   * and thus destroyed. To work around this, have the property be
   * a function that returns the value instead.
   *
   * @param base
   * @param override
   */
  static combineConfigValues<T>(
    base: object | Array<any>,
    override?: object | Array<any>,
  ): T {
    if (undefined === override) {
      return base as T;
    }

    const c: any = structuredClone(base);

    for (let [k, v] of Object.entries(override)) {
      if (Array.isArray(v)) {
        c[k] = FfNgxConfigHelper.combineConfigValues([], v);
      } else if (typeof v === 'object') {
        c[k] = FfNgxConfigHelper.combineConfigValues(c[k] || {}, v);
      } else {
        c[k] = v;
      }
    }

    return c as T;
  }
}
