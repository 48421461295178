import { UserFormErrorName } from './user-form-error';
import { FfNgxControlErrorMessages } from '../ngx-control-error/ff-ngx-error-messages.type';
import { FF_NGX_PHONE_NUMBER_ERROR_MESSAGES } from '../ngx-phone-number-input/phone-validation/ff-ngx-phone-number-control-error-messages';
import { FF_NGX_EMAIL_ADDRESS_ERROR_MESSAGES } from '../ngx-email-address-input/email-address-validation/ff-ngx-email-address-control-error-messages';

export const FF_NGX_USER_FORM_ERROR_MESSAGES: FfNgxControlErrorMessages<UserFormErrorName> =
  {
    ...FF_NGX_PHONE_NUMBER_ERROR_MESSAGES,
    ...FF_NGX_EMAIL_ADDRESS_ERROR_MESSAGES,
    verificationFailed: (error) => 'Noe gikk galt, prøv igjen',
    verificationInvalid: (error) => 'Ugyldig engangskode',
  };
