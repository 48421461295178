import { FfNgxPermissionResolver } from './ff-ngx-permission-resolver';
import { Observable, of } from 'rxjs';
import { FfNgxPermission } from './interfaces/ff-ngx.permission';
import { FfNgxPermissionTypesEnum } from './enums/ff-ngx-permission-types.enum';

export class FfNgxDefaultPermissionResolver extends FfNgxPermissionResolver {
  getPermission(): Observable<FfNgxPermission> {
    return of({
        type: FfNgxPermissionTypesEnum.PERMISSION_TYPE_NONE,
        name: FfNgxPermissionTypesEnum.PERMISSION_TYPE_NONE,
        access: false,
      } as FfNgxPermission);
  }

  getPermissionType(): FfNgxPermissionTypesEnum | string {
    return FfNgxPermissionTypesEnum.PERMISSION_TYPE_NONE;
  }
}
