<div [class.hidden]="hidden" class="loading-screen">
  <main aria-hidden="true" class="main">
    <svg
      fill="none"
      height="36"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 196 36"
      width="196"
      xmlns="http://www.w3.org/2000/svg">
      <title>Fagforbundet logo</title>
      <rect
        fill="#fa3e34"
        height="36"
        width="48" />
      <path
        fill="white"
        d="M25.5093 29.1777C25.5093 27.0369 26.3651 24.7415 27.8834 22.9865C28.4106 22.376 29.1007 21.739 29.9736 21.1721C29.8815 23.0804 28.918 25.911 25.7722 29.9138C25.6934 29.9111 25.6789 29.9111 25.5895 29.9111C25.5658 29.6667 25.5093 29.4275 25.5093 29.1777ZM9.93439 16.0236C9.12462 16.8152 8.08612 18.3098 8.08612 20.7757C8.08612 21.6386 8.22678 22.6324 8.5357 23.7464H4.29492V26.0392H11.7892L11.1753 24.4719C9.99617 21.4576 10.1184 19.0406 11.5237 17.6702C12.624 16.5958 14.4157 16.3289 15.9801 17.0042C17.7219 17.7548 18.7157 19.4383 18.7157 21.636V21.7205L20.9977 21.7272V21.6756C20.9991 19.3141 22.2374 17.5209 24.3183 16.8773C26.2744 16.2707 28.3304 16.8746 29.3163 18.3455C29.3938 18.4618 29.478 18.6045 29.5569 18.7683C28.2646 19.4674 27.1197 20.37 26.1613 21.4827C24.2868 23.6499 23.2325 26.5109 23.2325 29.2068C23.2325 29.9257 23.3061 30.6327 23.4612 31.3106L23.6624 32.1986L26.8725 32.2105L27.2156 31.785C31.1396 26.9365 32.2832 23.3566 32.2832 20.8272C32.2832 20.555 32.2504 20.3171 32.2241 20.07C33.5452 19.6101 35.1503 19.3485 37.0643 19.3987V26.0392H39.3451V17.2447L38.3 17.1574C35.8851 16.9526 33.6478 17.2103 31.6562 17.875C31.5142 17.5724 31.3644 17.2936 31.2066 17.0623C29.6646 14.7603 26.5544 13.785 23.6453 14.6849C22.2742 15.1091 20.8663 16.0434 19.904 17.509C19.1968 16.3553 18.1701 15.4514 16.8805 14.8964C14.4775 13.8603 11.688 14.3149 9.93439 16.0236Z" />
      <path
        fill="white"
        d="M12.962 7.94918C13.0895 8.43946 13.1802 8.92444 13.1802 9.30107C13.1802 9.61955 13.1303 9.86006 13.0264 9.96049C12.6807 10.2882 11.7671 10.1812 10.9875 9.95653V7.94918H12.962ZM13.664 6.07935H9.12744V11.2622L9.69928 11.5027C10.0108 11.6349 12.7977 12.7476 14.3029 11.3204C15.6792 10.0161 14.738 7.23168 14.5342 6.68723L14.3081 6.07935H13.664Z" />
      <path
        fill="white"
        d="M26.0412 7.64853C26.1923 7.80843 26.2541 8.09652 26.2541 8.43482C26.2541 8.82994 26.1582 9.27661 26.0385 9.69816H24.0417V7.71329C24.9448 7.4741 25.8256 7.41859 26.0412 7.64853ZM22.785 6.1341L22.1816 6.36008V11.5667H27.3374L27.5753 10.9919C27.7081 10.6774 28.8149 7.87714 27.3939 6.36536C26.0964 4.98044 23.3279 5.92795 22.785 6.1341Z" />
      <path
        fill="white"
        d="M39.0954 7.94918C39.2243 8.43946 39.3137 8.92444 39.3137 9.30107C39.3137 9.61955 39.2637 9.86006 39.1599 9.96049C38.8154 10.2882 37.9005 10.1812 37.1223 9.95653V7.94918H39.0954ZM39.7988 6.07935H35.2622V11.2622L35.8327 11.5027C36.1456 11.6349 38.9311 12.7476 40.4363 11.3204C41.8127 10.0161 40.8714 7.23168 40.6677 6.68723L40.4429 6.07935H39.7988Z"       />
      <path
        fill="#3C3C3B"
        d="M62.5433 17.1667V19.5242H65.8652V21.6201H62.5433V26.0405H60.001V14.9875H66.0611V17.1667H62.5433Z" />
      <path
        fill="#3C3C3B"
        d="M73.1133 19.4592C72.9345 18.9174 72.8044 18.41 72.69 17.9197H72.6571C72.5441 18.41 72.4297 18.9174 72.2509 19.4592L71.403 21.9793H73.9599L73.1133 19.4592ZM75.2797 26.0402L74.5961 23.9933H70.8023L70.1319 26.0402H67.6079L71.3229 14.9873H74.2057L78.0652 26.0402H75.2797Z" />
      <path
        fill="#3C3C3B"
        d="M85.7534 26.2215C82.187 26.2215 80.1665 24.1401 80.1665 20.7849C80.1665 17.2644 82.5603 14.825 86.3883 14.825C87.578 14.825 88.7177 15.0205 89.5656 15.2835L89.0608 17.608C88.2629 17.2988 87.3335 17.0992 86.274 17.0992C84.1904 17.0992 82.9034 18.4273 82.9034 20.5893C82.9034 22.6336 83.9774 23.9128 86.0308 23.9128C86.4212 23.9128 86.7801 23.8613 87.0719 23.7635V20.1453H89.6143V25.4999C88.5389 25.9426 87.1705 26.2215 85.7534 26.2215Z" />
      <path
        fill="#3C3C3B"
        d="M96.0305 17.1667V19.5242H99.3524V21.6201H96.0305V26.0405H93.4907V14.9875H99.5495V17.1667H96.0305Z" />
      <path
        fill="#3C3C3B"
        d="M107.66 17.0995C106.341 17.0995 105.086 18.1316 105.086 20.4416C105.086 22.5692 105.869 23.9131 107.611 23.9131C108.817 23.9131 110.234 23.0106 110.234 20.5063C110.234 18.281 109.337 17.0995 107.66 17.0995ZM107.465 26.2522C104.061 26.2522 102.399 24.1246 102.399 20.653C102.399 17.0506 104.858 14.7764 107.854 14.7764C110.754 14.7764 112.921 16.4626 112.921 20.3412C112.921 23.9779 110.51 26.2522 107.465 26.2522Z" />
      <path
        fill="#3C3C3B"
        d="M119.55 17.0014C119.289 17.0014 118.997 17.0199 118.85 17.0345V19.8664C118.981 19.8849 119.24 19.9008 119.485 19.9008C120.56 19.9008 121.243 19.3273 121.243 18.3943C121.243 17.5247 120.739 17.0014 119.55 17.0014ZM121.554 26.0404L118.997 21.7178H118.85V26.0404H116.309V14.9875C117.237 14.9227 118.426 14.9082 119.745 14.9082C122.336 14.9082 123.899 15.8385 123.899 18.1802C123.899 19.6708 122.921 20.8827 121.538 21.2434C121.831 21.6689 122.124 22.0799 122.384 22.4869L124.648 26.0404H121.554Z"       />
      <path
        fill="#3C3C3B"
        d="M131.082 21.3239C130.951 21.3239 130.43 21.3239 130.236 21.3424V23.9444C130.446 23.9774 130.854 23.9933 131.228 23.9933C132.369 23.9933 133.003 23.47 133.003 22.6176C133.003 21.7666 132.269 21.3239 131.082 21.3239ZM131.212 16.9709C130.822 16.9709 130.545 16.9854 130.236 17.0013V19.4249C130.446 19.4407 130.691 19.4407 130.985 19.4407C132.222 19.4407 132.727 18.9333 132.727 18.1008C132.727 17.3964 132.255 16.9709 131.212 16.9709ZM131.197 26.0747C130.528 26.0747 129.291 26.0403 127.693 26.0403V15.0045C128.704 14.957 130.284 14.9226 131.636 14.9226C133.998 14.9226 135.415 15.8569 135.415 17.7057C135.415 18.7364 134.876 19.6707 133.915 20.0962V20.1451C135.04 20.4425 135.723 21.4891 135.723 22.5859C135.723 24.3541 134.437 26.0747 131.197 26.0747Z" />
      <path
        fill="#3C3C3B"
        d="M143.542 26.2527C140.04 26.2527 139.192 24.3537 139.192 22.1441V14.9883H141.735V21.9975C141.735 23.2899 142.142 24.1092 143.704 24.1092C145.138 24.1092 145.692 23.5026 145.692 21.8495V14.9883H148.2V21.7014C148.2 24.8136 146.457 26.2527 143.542 26.2527Z" />
      <path
        fill="#3C3C3B"
        d="M158.412 26.041L155.824 21.2757C155.367 20.4246 154.976 19.5723 154.681 18.8534C154.73 19.6714 154.764 20.7854 154.764 21.832V26.041H152.32V14.988H155.578L157.989 19.3608C158.478 20.2608 158.933 21.2255 159.324 22.0791C159.243 21.1277 159.21 19.6714 159.21 18.6235V14.988H161.654V26.041H158.412Z" />
      <path
        fill="#3C3C3B"
        d="M169.439 17.0687C169.015 17.0687 168.673 17.0687 168.397 17.0991V23.8624C168.609 23.8955 168.951 23.9285 169.341 23.9285C171.084 23.9285 172.387 22.8489 172.387 20.4913C172.387 18.41 171.719 17.0687 169.439 17.0687ZM169.112 26.1235C167.957 26.1235 166.865 26.0747 165.855 26.0258V15.0217C166.719 14.9728 168.12 14.9226 169.603 14.9226C172.909 14.9226 175.108 16.3644 175.108 20.2918C175.108 24.0766 172.697 26.1235 169.112 26.1235Z" />
      <path
        fill="#3C3C3B"
        d="M178.56 26.041V14.988H184.749V17.1328H181.101V19.3278H184.554V21.4065H181.101V23.846H184.749V26.041H178.56Z" />
      <path
        fill="#3C3C3B"
        d="M192.861 17.1664V26.0402H190.337V17.1664H187.696V14.9873H195.5V17.1664H192.861Z" />
    </svg>

    <ff-ngx-loading-indicator size="medium" [stroke]="spinnerStroke" />
  </main>

  <div aria-live="assertive" class="visually-hidden" lang="nb" role="status">
    Vennligst vent, siden lastes
  </div>
</div>
