import { EnvironmentProviders } from '@angular/core';
import { environment } from '@environments/environment';
import {
  provideRouter,
  withDebugTracing,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { routes } from '../app.routes';

export function provideRouterWithConfig(): EnvironmentProviders {
  if (environment.router.debug) {
    return provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      }),
      withDebugTracing(),
    );
  }
  return provideRouter(
    routes,
    withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    withRouterConfig({
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    }),
  );
}
