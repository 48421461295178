import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FfNgxAuthStore } from './stores/ff-ngx-auth.store';
import { FfNgxPermission } from './interfaces/ff-ngx.permission';
import { FfNgxUser } from './interfaces/ff-ngx.user';
import { FF_NGX_PERMISSION_RESOLVERS } from './ff-ngx-permission-resolvers.injection-token';
import { FfNgxPermissionResolver } from './ff-ngx-permission-resolver';
import { FfNgxPermissionTypesEnum } from './enums/ff-ngx-permission-types.enum';
import { FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG } from './ff-ngx-auth-bundle.provider';
import { FfNgxAuthBundleInternalConfigType } from './types/ff-ngx-auth-bundle-internal-config.type';
import { FfNgxDefaultPermissionResolver } from './ff-ngx-default-permission-resolver';
import { FfNgxStorageService } from '../ngx-storage-bundle/ff-ngx-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FfNgxAuthService {
  private readonly _waitingForAuth$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  private readonly _noAccessEvents$: Subject<FfNgxPermission> =
    new Subject<FfNgxPermission>();

  constructor(
    private _authStore: FfNgxAuthStore,
    @Inject(FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG)
    private _config: FfNgxAuthBundleInternalConfigType,
    private _router: Router,
    private _oidcSecurityService: OidcSecurityService,
    @Optional()
    @Inject(FF_NGX_PERMISSION_RESOLVERS)
    private _permissionResolvers: FfNgxPermissionResolver[] = [],
    private _storageService: FfNgxStorageService,
  ) {
    // This ensures the creation of the store if it doesn't already exist
    this.setRememberMe(this.isRememberMe());
  }

  get waitingForAuth$(): Observable<boolean> {
    return this._waitingForAuth$.asObservable();
  }

  get noAccessEvents$(): Observable<FfNgxPermission> {
    return this._noAccessEvents$.asObservable();
  }

  authCompleted(): void {
    this._waitingForAuth$.next(false);
    this._oidcSecurityService.userData$
      .pipe(
        filter((userInfo: any) => {
          return !!userInfo;
        }),
        tap((userInfo: any) => {
          if (!userInfo.userData) {
            return;
          }

          const user = {
            sub: userInfo.userData?.sub,
            firstName: userInfo.userData?.given_name,
            lastName: userInfo.userData?.family_name,
          };

          this.setStoredUser(user);
        }),
      )
      .subscribe();
  }

  logout(): Observable<void> {
    if (this._config.debug) {
      console.log('Logging out...');
    }
    return this._oidcSecurityService.logoff().pipe(
      map(() => {
        if (this._config.debug) {
          console.log('Logged  out');
        }
      }),
    );
  }

  getPermissionResolverForPermissionType(
    type: FfNgxPermissionTypesEnum | string,
  ): FfNgxPermissionResolver {
    let r: FfNgxPermissionResolver | undefined = this._permissionResolvers.find(
      (permissionResolver) => {
        return permissionResolver.getPermissionType() === type;
      },
    );

    if (r) {
      return r;
    }

    throw new Error('No permission resolver found for type ' + type);
  }

  getPermissionResolversForPermissionTypes(
    types: Array<FfNgxPermissionTypesEnum | string>,
  ): FfNgxPermissionResolver[] {
    return types.map((t) => {
      return this.getPermissionResolverForPermissionType(t);
    });
  }

  getPermissionForPermissionType(
    type: FfNgxPermissionTypesEnum | string,
    triggerNoAccessEvent: boolean = false,
    addToAuthStore: boolean = true,
  ): Observable<FfNgxPermission> {
    let r: FfNgxPermissionResolver = new FfNgxDefaultPermissionResolver();

    if (this._config.debug) {
      console.log('Looking for permission resolver for type ' + type);
    }

    this._permissionResolvers.forEach((permissionResolver) => {
      if (permissionResolver.getPermissionType() === type) {
        r = permissionResolver;
      }
    });

    if (
      r.getPermissionType() === FfNgxPermissionTypesEnum.PERMISSION_TYPE_NONE
    ) {
      console.error(
        "Couldn't find permission resolver for type " +
          type +
          '. Defaulting to no access.',
      );
    } else {
      if (this._config.debug) {
        console.log('Found permission resolver for type ' + type);
      }
    }

    return r.getPermission().pipe(
      tap((p: FfNgxPermission) => {
        if (triggerNoAccessEvent && !p.access) {
          this._noAccessEvents$.next(p);
        }

        if (addToAuthStore) {
          this._authStore.permissions = this._authStore.permissions
            ? [...this._authStore.permissions, p]
            : [p];
        }
      }),
      catchError((e) => {
        console.error(
          'Something went wrong when fetching permission for type ' + type,
          e,
        );
        return new FfNgxDefaultPermissionResolver().getPermission().pipe(
          tap((p) => {
            if (triggerNoAccessEvent) {
              this._noAccessEvents$.next(p);
            }
          }),
        );
      }),
    );
  }

  getPermissions(): Observable<FfNgxPermission[]> {
    if (this._authStore.permissions) {
      if (this._config.debug) {
        console.log('Getting permissions from store');
      }
      return of(this._authStore.permissions);
    }

    if (this._config.debug) {
      console.log('Resolving permissions');
    }

    return this._resolvePermissions();
  }

  getSelf(): Observable<FfNgxUser | undefined> {
    return this._authStore.user$;
  }

  getStoredUuid(): string | void {
    return this._authStore.readItem(this._config.storageKeys.uuid);
  }

  getStoredFirstName(): string | void {
    return this._authStore.readItem(this._config.storageKeys.firstName);
  }

  getStoredLastName(): string | void {
    return this._authStore.readItem(this._config.storageKeys.lastName);
  }

  isRememberMe(): boolean {
    return !!this._authStore.readItem(this._config.storageKeys.rememberMe);
  }

  navigateIfStoredRouteExists(): Observable<boolean> {
    const storedUrl = this._authStore.readItem<string>(
      this._config.storageKeys.redirectRoute,
      true,
    );

    const storedRouteTimestamp = this._authStore.readItem<number>(
      this._config.storageKeys.redirectRouteTimestamp,
      true,
    );
    const storedRouteExpired =
      storedRouteTimestamp == null ||
      new Date().getTime() >
        storedRouteTimestamp + this._config.ttl.redirectRoute;

    if (storedUrl && !storedRouteExpired) {
      const parsedUrl = new URL(storedUrl);
      if (this._config.debug) {
        console.log('Found and parsed stored route: ' + parsedUrl.toString());
      }
      return fromPromise(
        this._router.navigateByUrl(this.getPathAndSearchFromUrl(parsedUrl)),
      );
    }

    const foundUrl = !!storedUrl;

    if (this._config.debug && foundUrl && storedRouteExpired) {
      console.log('Stored route had expired');
    }

    if (this._config.debug && !foundUrl) {
      console.log('No stored route was found');
    }

    return of(false);
  }

  /**
   * Saves the requested route to storage.
   */
  setStoredRedirectRoute(url: URL): void {
    if (this._config.debug) {
      console.log('Storing redirect route: ' + url.toString());
    }
    this._authStore.storeItem<string>(
      this._config.storageKeys.redirectRoute,
      url.toString(),
    );
    this._authStore.storeItem<number>(
      this._config.storageKeys.redirectRouteTimestamp,
      new Date().getTime(),
    );
  }

  /**
   *  Saves user name and uuid
   */
  setStoredUser(user: FfNgxUser): void {
    if (this._config.debug) {
      console.log('Storing user info:', { ...user });
    }
    this._authStore.user$ = user;
  }

  deleteStoredUser(): void {
    this._authStore.deleteItem(this._config.storageKeys.uuid);
    this._authStore.deleteItem(this._config.storageKeys.user);
    this._authStore.deleteItem(this._config.storageKeys.firstName);
    this._authStore.deleteItem(this._config.storageKeys.lastName);
    this._authStore.deleteItem(this._config.storageKeys.rememberMe);
  }

  deleteAuthStore(): void {
    this.deleteStoredUser();
    this._oidcSecurityService.logoffLocal();
  }

  setRememberMe(rememberMe: boolean): void {
    if (rememberMe) {
      this._storageService.useLocalStorage(this._config.storageName);
    } else {
      this._storageService.useSessionStorage(this._config.storageName);
    }
    this._authStore.storeItem<boolean>(
      this._config.storageKeys.rememberMe,
      rememberMe,
    );
  }

  private getPathAndSearchFromUrl(url: URL): string {
    return url.pathname + url.search;
  }

  /**
   * Each of the inner observables in the forkJoin MUST handle (all) errors and return an Observable of the FfNgxPermission interface
   */
  private _resolvePermissions(): Observable<FfNgxPermission[]> {
    return forkJoin(
      this._permissionResolvers.map((pr) => pr.getPermission()),
    ).pipe(
      tap((permissions) => {
        if (this._config.debug) {
          console.log('Storing permissions');
        }
        this._authStore.permissions = permissions;
      }),
    );
  }
}
