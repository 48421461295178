import { FfNgxEndpointDefinition } from '../types/ff-ngx-endpoint-definition';
import { FfNgxApiConfiguration } from '../types/ff-ngx-api-configuration';

export function createEndpointDefinition(
  path: string,
  apiConfiguration: FfNgxApiConfiguration<any>,
): FfNgxEndpointDefinition {
  return {
    path,
    apiConfiguration,
  };
}
