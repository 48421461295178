import {
  createApiConfiguration,
  FfNgxEndpointDefinition,
} from '@fagforbundet/ngx-components';
import { environment } from '@environments/environment';
import { idApiEndpoints } from '@config/apis/id/id-api-endpoints';

export const idApiConfig = createApiConfiguration<typeof idApiEndpoints>({
  baseUrl: environment.apis.id.baseUrl,
  endpoints: idApiEndpoints,
});

export const idApiNoAuthEndpoints: FfNgxEndpointDefinition[] = [
  idApiEndpoints.GET_AUTHORIZE,
  idApiEndpoints.GET_JWKS,
  idApiEndpoints.GET_OPENID_CONFIGURATION,
  idApiEndpoints.GET_TOKEN,
];
