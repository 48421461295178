import { DateErrorName } from './date-error.type';
import { FfNgxControlErrorMessages } from '../../ngx-control-error/ff-ngx-error-messages.type';

export const FF_NGX_DATE_ERROR_MESSAGES: FfNgxControlErrorMessages<DateErrorName> =
  {
    dateInvalid: (error) => 'Ugyldig datoformat, riktig format er DD.MM.ÅÅÅÅ',
    dateMax: ({ max, current }) =>
      `Valgt dato kan ikke være etter ${(max as Date).toLocaleDateString().split('/').join('.')}`,
    dateMin: ({ min, current }) =>
      `Valgt dato kan ikke være før ${(min as Date).toLocaleDateString().split('/').join('.')}`,
  };
