import { FfNgxStorage } from './ff-ngx-storage';
import { FfNgxStorageData } from '../types/ff-ngx-storage-data';
import { FfNgxStorageDataChangeEventType } from '../enums/ff-ngx-storage-data-change-event-type';
import { dateTimeReviver } from '../utility-functions/date-time-reviver';

export abstract class FfNgxNativeStorageStorage extends FfNgxStorage {
  protected activeStorage: Storage;

  get length(): number {
    return this.activeStorage.length;
  }

  resetValues(): void {
    const keys = this.getKeys(this.name).get(this.name) || [];

    keys.forEach((bareKey) => {
      let value = this.getItem(bareKey)!;

      if (value && this.isExpired(value)) {
        this.removeItem(bareKey);
        return;
      }

      this.setItem(bareKey, value);
    });
  }

  clear(): void {
    const keys = this.getKeys(this.name).get(this.name) || [];

    keys.forEach((bareKey) => {
      this.removeItem(bareKey);
    });
  }

  getItem(
    key: string,
    parseDatesInData: boolean = true,
  ): FfNgxStorageData | null {
    const d = this.activeStorage.getItem(this.buildKey(key));

    if (!d) {
      return null;
    }

    let parsed = JSON.parse(d, dateTimeReviver) as FfNgxStorageData;

    if (parseDatesInData) {
      return parsed;
    }

    parsed.data = JSON.parse(JSON.stringify(parsed.data));

    return parsed;
  }

  key(index: number): string | null {
    return this.activeStorage.key(index);
  }

  removeItem(key: string): void {
    const k = this.buildKey(key);
    const value = this.activeStorage.getItem(k);

    this.activeStorage.removeItem(k);

    if (null !== value) {
      this._dataChanges$.next({
        type: FfNgxStorageDataChangeEventType.REMOVE,
        key: k,
        data: JSON.parse(value, dateTimeReviver),
        createdAt: new Date(),
        store: this,
      });
    }
  }

  setItem(key: string, value: any, ttl?: number): void {
    const k = this.buildKey(key);

    const oldData = this.activeStorage.getItem(k);
    const exists = null !== oldData;

    const newValue = this.buildValue(value, ttl, this.getTimeoutHandler(key));

    this.activeStorage.setItem(this.buildKey(key), JSON.stringify(newValue));

    this._dataChanges$.next({
      type: exists
        ? FfNgxStorageDataChangeEventType.CHANGE
        : FfNgxStorageDataChangeEventType.ADD,
      key: k,
      data: newValue,
      oldData: oldData ? JSON.parse(oldData, dateTimeReviver) : undefined,
      createdAt: new Date(),
      store: this,
    });
  }

  getKeys(
    storeName?: string,
    withStoreName: boolean = false,
  ): Map<string, string[]> {
    const keys = new Map<string, string[]>();

    for (let key in { ...this.activeStorage }) {
      if (!key.startsWith(FfNgxStorage.STORE_NAME_PREFIX)) {
        continue;
      }

      const storeNameAndKey = key.substring(
        FfNgxStorage.STORE_NAME_PREFIX.length,
      );
      const storeNameAndKeySplit = storeNameAndKey.split(/\.(.*)/);
      const storeNameFromKey = storeNameAndKeySplit[0];
      const keyName = storeNameAndKeySplit[1];

      if (storeName && storeName !== storeNameFromKey) {
        continue;
      }

      let k: string[] = keys.has(storeNameFromKey)
        ? keys.get(storeNameFromKey)!
        : [];

      if (withStoreName) {
        k.push(storeNameAndKey);
      } else {
        k.push(keyName);
      }

      keys.set(storeNameFromKey, k);
    }

    return keys;
  }
}
