import { FfNgxControlErrorMessages } from '@fagforbundet/ngx-components';
import { FF_NGX_USER_FORM_ERROR_MESSAGES } from '@fagforbundet/ngx-components';
import { FF_NGX_FILE_UPLOAD_ERROR_MESSAGES } from '@fagforbundet/ngx-components';
import { FF_NGX_DATE_ERROR_MESSAGES } from '@fagforbundet/ngx-components';

export const controlErrorMessages: FfNgxControlErrorMessages = {
  ...FF_NGX_USER_FORM_ERROR_MESSAGES,
  ...FF_NGX_FILE_UPLOAD_ERROR_MESSAGES,
  ...FF_NGX_DATE_ERROR_MESSAGES,
  wrongName: ({ requiredName }) => `Navnet burde egentlig være ${requiredName}`,
};
