import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FfNgxPermission } from '../interfaces/ff-ngx.permission';
import { FfNgxUser } from '../interfaces/ff-ngx.user';
import { FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG } from '../ff-ngx-auth-bundle.provider';
import { FfNgxAuthBundleInternalConfigType } from '../types/ff-ngx-auth-bundle-internal-config.type';
import { FfNgxStorageService } from '../../ngx-storage-bundle/ff-ngx-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FfNgxAuthStore {
  private readonly _user$ = new BehaviorSubject<FfNgxUser | undefined>(
    undefined,
  );
  private readonly _permissions$ = new BehaviorSubject<
    FfNgxPermission[] | undefined
  >(undefined);

  constructor(
    @Inject(FF_NGX_AUTH_BUNDLE_PROVIDER_CONFIG)
    private _config: FfNgxAuthBundleInternalConfigType,
    private _storageService: FfNgxStorageService,
  ) {}

  get permissions(): FfNgxPermission[] | undefined {
    return this._permissions$.value;
  }

  set permissions(permissions: FfNgxPermission[] | undefined) {
    this._permissions$.next(permissions);
  }

  get user$(): Observable<FfNgxUser | undefined> {
    return this._user$.asObservable();
  }

  set user$(user: FfNgxUser) {
    this.storeItem(this._config.storageKeys.user, user);
    this.storeItem(this._config.storageKeys.uuid, user.sub);
    if (user.firstName) {
      this.storeItem(this._config.storageKeys.firstName, user.firstName);
    }
    if (user.lastName) {
      this.storeItem(this._config.storageKeys.lastName, user.lastName);
    }
    this._user$.next(user);
  }

  deleteItem(key: string): void {
    let store;
    try {
      store = this._storageService.getStore(this._config.storageName);
    } catch (e) {
      return;
    }
    store.removeItem(key);
  }

  readItem<T>(key: string, removeFromStore = false): T | undefined {
    let store;
    try {
      store = this._storageService.getStore(this._config.storageName);
    } catch (e) {
      return undefined;
    }
    const data = store.getItem(key);
    if (data) {
      if (removeFromStore) {
        this.deleteItem(key);
      }
      return data.data;
    }
    return undefined;
  }

  storeItem<T>(key: string, value: T): void {
    this._storageService.getStore(this._config.storageName).setItem(key, value);
  }
}
