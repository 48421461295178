import { FfNgxApiConfiguration } from '../types/ff-ngx-api-configuration';
import { FfNgxEndpointRecords } from '../types/ff-ngx-endpoint-records';
import { createEndpointDefinition } from './create-endpoint-definition';

export function createApiConfiguration<
  TEndpointCollection extends FfNgxEndpointRecords,
>(options: {
  baseUrl: string;
  endpoints: TEndpointCollection;
}): FfNgxApiConfiguration<TEndpointCollection> {
  const c: FfNgxApiConfiguration<TEndpointCollection> = {
    baseUrl: options.baseUrl,
    endpoints: options.endpoints,
  };

  const tmp: FfNgxEndpointRecords = {};

  Object.entries(c.endpoints).forEach(([key, endpointDefinition]) => {
    if (undefined === endpointDefinition.apiConfiguration) {
      tmp[key] = createEndpointDefinition(endpointDefinition.path, c);
    }
  });

  c.endpoints = tmp as TEndpointCollection;

  return c;
}
