import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';
import { IdApiInterface } from '@environments/assets/environment.interface';

export const idApiTargets: EnvironmentTargetInterface = {
  build: {
    ngxComponentLibraryClientId: '', // https://id.fagforbundet.build/clients/oauth/<uuid>
    baseUrl: 'https://api.id.fagforbundet.build',
  } as IdApiInterface,
  development: {
    ngxComponentLibraryClientId: 'ebce3326-6e43-40c3-afa0-5ba89841ca6d', // https://id.fagforbundet.localhost:8080/clients/oauth/<uuid>
    baseUrl: 'https://api.id.fagforbundet.localhost:8080',
  } as IdApiInterface,
  production: {
    ngxComponentLibraryClientId: '',
    baseUrl: 'https://api.id.fagforbundet.no',
  } as IdApiInterface,
  staging: {
    ngxComponentLibraryClientId: 'ebce3326-6e43-40c3-afa0-5ba89841ca6d',
    baseUrl: 'https://api.id.fagforbundet.dev',
  } as IdApiInterface,
};
