import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const idApiEndpoints = {
  GET_AUTHORIZE: {
    path: '/v1/oauth/authorize',
  },
  GET_JWKS: {
    path: '/.well-known/jwks',
  },
  GET_OPENID_CONFIGURATION: {
    path: '/.well-known/openid-configuration',
  },
  GET_TOKEN: {
    path: '/v1/oauth/token',
  },
  GET_USER_INFO: {
    path: '/v1/oauth/user-info',
  },
} satisfies FfNgxEndpointRecords;
